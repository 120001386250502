body {
  margin: 0;
  background: #ececec;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

body{
  background-color: #ececec !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ant-table table { font-size: 11px; }

.table-container {
  display: flex;
  justify-content: center;
}
.table-box{
  max-width: 1200px;
}

.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}
  [data-theme="dark"] .site-card-border-less-wrapper {
    background: #303030;
  }

  .login {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .pre-login-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .button-div{
    display: inline-block;
  }

  .subtitle {
    color: #ccc;
    margin-top: -5px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 0.8rem;
  }


  .text-connection {
    font-size: 0.7rem;
      font-weight: bold;
  }
  
  .auth0-button {
    display: flex;
      background-color: #fff;
      background-size: cover;
      background-position: center;
      border: 1px solid #eee;
      border-radius: 10px;
      align-items: center;
  }
  
  .auth0-button:hover {
    background-color: #eee;
  }

  .text-button-connexion {
    padding-right: 13px;
  }
